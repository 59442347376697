import React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import Feedback from "../components/feedback"

const FeedbackPage = () => (
  <Layout>
    <Feedback />
  </Layout>
)

export default FeedbackPage

export const Head = () => <Seo title="Feedback | Universal Game Protection" />
