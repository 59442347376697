import React from "react"
import {
  intro,
  container,
  feedback,
  feedbackContainer,
  review,
  details,
} from "./styles.module.less"

const Feedback = () => {
  return (
    <div className={feedbackContainer}>
      <h1>Feedback</h1>
      <div className={intro}>
        <p>
          The only complaint we’ve ever had was that the participants wanted the
          training sessions to be longer. So, how effective is the training?
          What sort of impact does training have on the participants? Our
          clients are the most qualified people to answer those questions.
        </p>
      </div>
      <div className={container}>
        <blockquote>
          Proof of the effectiveness of Sal's training happened sooner than I
          expected
        </blockquote>

        <div className={feedback}>
          {reviews.map(({ quote, name, title, org }) => (
            <QuoteComponent quote={quote} name={name} title={title} org={org} />
          ))}
          <h2>Additional feedback from staff</h2>
          {staffReviews.map(({ quote, name, title, org }) => (
            <QuoteComponent quote={quote} name={name} title={title} org={org} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Feedback

const QuoteComponent = ({ quote, name, title, org }) => {
  return (
    <div className={review}>
      <p>
        <i>{quote}</i>
      </p>
      <div className={details}>
        <p>
          <b>{name}</b>
        </p>
        <p>
          <b>{title}</b>
        </p>
        <p>
          <b>{org}</b>
        </p>
      </div>
    </div>
  )
}

const reviews = [
  {
    quote: `Proof of the effectiveness of Sal's training happened sooner than I expected. Exactly 7 days after the training concluded, one of our Observers spotted a Blackjack dealer breaking procedures in a way that Sal has demonstrated, and upon review, discovered the dealer was engaged in serious misconduct. The dealer was very slick in his moves, and I firmly believe that his activities were uncovered thanks in large part to the training provided by Sal.`,
    name: `Todd Stuart`,
    title: `Surveillance Director`,
    org: `Otoe-Missouria Gaming Commission`,
  },
  {
    quote: `...I determined that my managers, and supervisors would benefit from being exposed to the latest game protection, and cheating methods used in the business today. My first thought was to contact Sal Piacente, because I had used his services in the late 80’s when I was casino manager at the Tropicana in Atlantic City. He left a lasting impact on my staff and me then, and I knew my current staff would thoroughly enjoy, and learn immensely from his teachings now. I knew Sal had spent his entire career examining and understanding the latest cheating techniques, tools, and mode of operations used by individuals, or teams that compromised a casino’s profitability. I knew that Sal with his knowledge, and incredible teaching style would drive home the point, and he did.`,
    name: `Bill Hood`,
    title: `Director of Table Games`,
    org: `Argosy Casino`,
  },
  {
    quote: `I took a poll of our folks that attended the training, from Operators to Managers and the feedback on the training was very positive. There are many reasons why folks enjoy your training, but I think the main reason is because you don’t just do magic tricks, you actually show the scam, define the process, and show the impact if the procedures are not followed. It’s also apparent that you truly enjoy teaching and are passionate about the content, this shows throughout your sessions. Everyone enjoyed the material and learned something from it.`,
    name: `Mike Stanciu`,
    title: `Regional Surveillance Manager`,
    org: `Ontario Lottery and Gaming Corporation`,
  },
  {
    quote: `I gained more knowledge from your two day seminar than all the previous seminars I have attended in the last 7 years combined.`,
    name: `Ryan Sanada`,
    title: `Assitant Surveillance Supervisor`,
    org: `Seven Feathers Casino Resort`,
  },
  {
    quote: `I cannot thank you enough for the trip you made here to our property, with the short notice that you were given. I can definitely already tell that you made an impact on our department here, I believe that a majority of them really took in what you taught and opened their eyes to the possibilities of the player.`,
    name: `Dusty Day`,
    title: `Blackjack Manager`,
    org: `First Council Casino`,
  },
  {
    quote: `Excellent! It’s nice to be able to see the move rather than see pictures of the move being performed, it makes quite a difference.`,
    name: `Richard`,
    title: `Surveillance Operator`,
    org: ``,
  },
  {
    quote: `You are a very knowledgeable expert, a patient instructor who ensures that his students understand the subject matter presented, and you’ve managed to remain just a regular guy through it all (which is apparently not an easy thing to do in your biz, as evidenced by the bloated egos and insincerity I’ve witnessed from a few self-proclaimed experts I’ve had the misfortune to meet during my career, and whose seminars I’ve suffered through. You are truly among the elite in your field, my friend.`,
    name: `Bruce Samboy`,
    title: `Gaming Commission Officer`,
    org: ``,
  },
  {
    quote: `I wanted to commend you for an incredibly informative experience. To your credit, I woke up at 4pm Tuesday to spent the evening with my family... Had to be at work at 11pm, worked until 7... filled time until your seminar started at 10, so by halfway through your class, I’d already been up 24 hrs. I was dreading the day for quite a while, but despite all of this, you had my complete and focused attention. Again thank you so much for the seminar... It was more than worth the time!`,
    name: `Russ`,
    title: `Casino Floorperson`,
    org: ``,
  },
  {
    quote: `I thoroughly enjoyed your presentation; it was an excellent insight into current scams and areas of risk. Cheers.`,
    name: `Richard Cook`,
    title: `General Manager- Surveillance`,
    org: `Crown Casino, Melbourne, Australia`,
  },
  {
    quote: `Your willingness to customize the course materials for the Canadian Gaming Industry and your informative yet energetic delivery and presentation of these topics shows the level of commitment and professionalism that you add to educating Canadian Gaming Professionals. To quote the participants: “Excellent examples, very knowledgeable of information. Very well done.`,
    name: `Judith Hayes, Director and Matt Eldridge, Human Resources Consultant`,
    title: ``,
    org: `Canadian Gaming Centre of Excellence`,
  },
  {
    quote: `Your willingness to lend your experience and expertise to the conference helped us produce, what we have been told by many attending this year’s Summit, the best conference program ever presented in the 11 years of the Canadian Gaming Summit.`,
    name: `Paul Burns`,
    title: `Vice President, Public Affairs`,
    org: ``,
  },
  {
    quote: `My team and I loved your training and I honestly can say that there has been nothing negative said whatsoever. As for the feedback here are some things a few of my staff had to say:`,
    name: `Rhonda Connors`,
    title: `Surveillance Manager`,
    org: `Great Blue Heron Casino`,
  },
]

const staffReviews = [
  {
    quote: `The training was beneficial. Sal didn’t just show us things, he broke it down step by step and explained it thoroughly. From just one day of training with Sal, I feel more knowledgeable and better prepared as a surveillance operator.`,
    name: `Bob- just promoted to Dual Shift Manager Operator`,
    title: ``,
    org: ``,
  },
  {
    quote: `Amazing training course. Extremely interesting and informative. Too bad it was only for one day.`,
    name: `Marc- Surveillance Operator`,
    title: ``,
    org: ``,
  },
  {
    quote: `The training was GREAT. I learned so much and it opened my eyes to the impossible. He was very clear about what he was teaching and was extremely knowledgeable.`,
    name: `Amanda-Surveillance Operator`,
    title: ``,
    org: ``,
  },
  {
    quote: `Sal has an incredible wealth of knowledge but as important is able to impart that knowledge with ease of delivery. He is engaging and entertaining.`,
    name: `Brandon- Dual Shift Manager Operator`,
    title: ``,
    org: ``,
  },
  {
    quote: `There were two things that differed this time from taking training from professionals in the past. Sal was very personable and has a friendly demeanor which makes the learning experience so much more pleasant and the hands on demonstrations, inviting all to participate. If i had one request it would be to have this same trainer return when possible to take the extra time needed to delve deeper into his subject matter.`,
    name: `John- Shift Manager`,
    title: ``,
    org: ``,
  },
  {
    quote: `Sal’s training was hands down the best I’ve had in the 11 years that I have worked here. He trains by educating and learns at the same time. He treated everyone like an equal and did not center anyone out. He was knowledgeable and I think even more than he let on. I would love to have him back again, I feel we could learn a lot more from him and this would benefit the whole casino.`,
    name: `Christy- Surveillance Operator (just accepted a position with Compliance)`,
    title: ``,
    org: ``,
  },
]
